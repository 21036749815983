<template>
  <v-container fluid class="mb-10">
    <v-row style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-row class="pa-10 pt-5">
            <v-col cols="12">
              <v-card class="mb-10" elevation="0">
                <v-toolbar
                  elevation="0"
                  style="background: #f5f5f5"
                  height="100px"
                >
                  <div>
                    <p class="mb-0 text-h4 font-weight-bold">
                      Order ID : {{ orderInfo.orderDetails.order_id }}
                    </p>
                    <p class="mb-0 text-h4 font-weight-bold" v-if="orderInfo.orderDetails.oder_date">
                      Order Placed : {{ new Date(orderInfo.orderDetails.oder_date).toLocaleString() }}
                    </p>
                    <p class="mb-0 text-h4 font-weight-bold" v-if="orderInfo.orderDetails.user_mail">
                      Order Placed By : {{ orderInfo.orderDetails.user_mail }}
                    </p>
                  </div>
                  <v-spacer></v-spacer>
                </v-toolbar>

                <v-card-text class="pa-0">
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="itemsWithIndex"
                        :loading="loading"
                        loading-text="Loading... Please wait"
                        hide-default-footer
                        class="elevation-1"
                      >
                        <template v-slot:item.time="{ item }">
                          <!-- {{ new Date(item.created_at).toDateString() }} -->
                          10/12/2021
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: true,

      menu1: false,
      menu2: false,
      start_date: new Date().toISOString().substr(0, 10),
      end_date: new Date().toISOString().substr(0, 10),

      headers: [
        { text: "S/N", value: "index", sortable: false },
        { text: "Bar-Code", value: "product_barcode" },
        { text: "Color", value: "product_color" },
        { text: "Size", value: "product_size" },
        { text: "Vat", value: "total_vat" },
        { text: "Discount", value: "total_discount" },
        { text: "Quantity", value: "quantity" },
        { text: "Purchase Price", value: "purchase_price" },
        { text: "Selling Price", value: "selling_price" },
      ],
      orderInfo: {
        orders: [],
        orderDetails : {}
      },
    };
  },
  computed: {
    itemsWithIndex() {
      return this.orderInfo.orders.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  methods: {
    getOrder() {
      // Get the Order details
      axios
        .get(
          `productdetails/individual_order_details/${this.$route.query.order_id}/`
        )
        .then((response) => {
          this.loading = false
          this.orderInfo.orders = response.data.data;
          this.orderInfo.orderDetails = response.data.oder_info;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getOrder();
  },
};
</script>


<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>